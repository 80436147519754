<template>
  <h1>Simple Change Case</h1>
  <el-row style="margin-bottom: 20px;">
    <el-col
      :span="24"
    >
      <div
        class="grid-content"
        style="padding: 20px; background-color: #1a1a1a"
      >
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
        >
          <el-form-item
            label="Input"
            class="el-form-item-label"
          >
            <el-input
              v-model="input"
              type="textarea"
              :rows="5"
              placeholder="Please input"
              @input="onInputText"
            />
          </el-form-item>
          <el-form-item
            label="Change Case"
            class="item-label"
          >
            <el-radio-group
              v-model="changeCase"
              @change="onInputText"
            >
              <el-radio
                label="pascal"
              >
                Pascal
              </el-radio>
              <el-radio
                label="camel"
              >
                Camel
              </el-radio>
              <el-radio
                label="kebab"
              >
                Kebab
              </el-radio>
              <el-radio
                label="snake"
              >
                Snake
              </el-radio>
              <el-radio
                label="constant"
              >
                Constant
              </el-radio>
              <el-radio
                label="dot"
              >
                Dot
              </el-radio>
              <el-radio
                label="path"
              >
                Path
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <div style="text-align: center;">
              <i
                class="el-icon-arrow-down"
                style="font-size: 2em;"
              />
            </div>
          </el-form-item>
          <el-form-item
            label="Output"
          >
            <el-input
              v-model="output"
              type="textarea"
              :rows="5"
              readonly
            />
          </el-form-item>
          <el-form-item>
            <el-button
              style="float: right"
              type="primary"
              @click="onClickCopyButton"
            >
              Copy
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-col>
  </el-row>
  <el-row style="margin-bottom: 20px;">
    <el-col :span="24">
      <el-table
        :data="histories"
        style="width: 100%;"
        :header-cell-style="{ background: '#121212'}"
        :cell-style="{ background: '#1a1a1a' }"
        :border="false"
      >
        <el-table-column
          prop="date"
          label="Date"
          width="120"
        />
        <el-table-column
          prop="name"
          label="Version"
          width="80"
        />
        <el-table-column
          prop="description"
          label="Description"
        />
      </el-table>
    </el-col>
  </el-row>
</template>

<script type="ts">

import {defineComponent, ref} from "vue";

export default defineComponent ({
  name: "TextEncode",
  setup() {
    return {
      changeCase: ref('pascal'),
      input: ref(''),
      output: ref(''),
      histories: [{
        date: '2021/03/31',
        name: '0.9.',
        description: 'init.'
      }]
    }
  },
  methods: {
    onInputText() {
      let changeCase = this.changeCase
      let input = this.input
      if (input) {
        //input = input.toLowerCase()
        input = input
            //.replace(/-/g, ' ')
            //.replace(/_/g, ' ')
            //.replace(/\./g, ' ')
            //.replace(/\//g, ' ')
            .replace(/\B(?:([A-Z])(?=[a-z]))|(?:(?<=[a-z0-9])([A-Z]))|-|_|\.|\//g, ' $1$2')
            .toLowerCase();
        //console.log(input);
        switch (changeCase) {
          case 'pascal':
            this.output = input
                .replace(/\s(.)/g, function($1) {
                  return $1.toUpperCase()
                })
                .replace(/\s/g, '')
                .replace(/^(.)/, function($1) {
                  return $1.toUpperCase()
                })
            break;
          case 'camel':
            this.output = input
                .replace(/\s(.)/g, function($1) {
                  return $1.toUpperCase()
                })
                .replace(/\s/g, '')
                .replace(/^(.)/, function($1) {
                  return $1.toLowerCase()
                })
            break;
          case 'kebab':
            this.output = input
                .replace(/\s/g, '-')
            break;
          case 'snake':
            this.output = input
                .replace(/\s/g, '_')
            break;
          case 'constant':
            this.output = input
                .replace(/\s/g, '_')
                .replace(/\w/g, function($1) {
                  return $1.toUpperCase()
                })
            break;
          case 'dot':
            this.output = input
                .replace(/\s/g, '.')
            break;
          case 'path':
            this.output = input
                .replace(/\s/g, '/')
            break;
        }


      } else {
        this.output = ''
      }
    },
    onClickCopyButton() {
      let text = this.output
      navigator.clipboard.writeText(text).catch((e) => {
        console.error(e)
      })
      this.$message({
        message: 'Copied.',
        type: 'success'
      })
    }

  }
})
</script>

<style scoped>
/*.el-row {*/
/*  margin-bottom: 20px;*/
/*&:last-child {*/
/*   margin-bottom: 0;*/
/* }*/
/*}*/
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
h1 {
  color: white;
}

.grid-content form.el-form .el-form-item label {
  color: white;
}
.el-form-item-label .el-form-item__label {
  color: white;
}


.item-label label { color: wheat; }

.el-table .version-table-row {
  background-color: pink;
}
.el-table .version-table-header {
  background-color: lightblue;
  color: #fff;
  font-weight: 500;
}
.el-radio {
  padding-bottom: 10px;
}


</style>